<template>
    <nav
        class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl bg-white mt-3 mx-4"
        v-bind="$attrs"
        id="navbarBlur"
        data-scroll="true"
        :class="isAbsolute ? 'mt-4' : 'mt-0'"
    >
        <div class="px-3 py-1 container-fluid">
            <breadcrumbs :currentPage="breadcrumbs" :color="color" />
            <div
                class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
                id="navbar"
            >
                <div class="pe-md-3 d-flex align-items-center ms-md-auto"></div>
                <ul class="navbar-nav justify-content-end gap-3">
                    <li class="nav-item d-flex justify-content-end gap-1">
                        <span>{{ user.user.name }}</span>
                        <account-icon
                            :size=26
                        />
                    </li>
                    <li class="nav-item">
                        <a
                            href="javascript:void(0)"
                            class="d-flex flex-row justify-content-end gap-1"
                            @click="logout"
                        >
                            <span>Выход</span>
                            <logout-icon
                                :size=26
                            />
                        </a>
                    </li>
                    <li
                        class="nav-item d-xl-none ps-3 d-flex align-items-center"
                    >
                        <a
                            href="#"
                            @click="toggleMinimizeNavbar"
                            class="p-0 nav-link text-body lh-1"
                            id="iconNavbarSidenav"
                        >
                            <div class="sidenav-toggler-inner">
                                <i class="sidenav-toggler-line"></i>
                                <i class="sidenav-toggler-line"></i>
                                <i class="sidenav-toggler-line"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapState } from "vuex";

import AccountIcon from "vue-material-design-icons/Account.vue";
import LogoutIcon from "vue-material-design-icons/Logout.vue";

export default {
    name: "navbar",
    props: ["color"],
    components: {
        Breadcrumbs,

        AccountIcon,
        LogoutIcon,
    },
    computed: {
        ...mapState(["isAbsolute", "user", "breadcrumbs"]),
    },
    methods: {
        ...mapMutations(["navbarMinimize"]),
        toggleMinimizeNavbar() {
            this.navbarMinimize();
        },
        logout() {
            this.$store.dispatch("logout");
        },
    },
};
</script>
