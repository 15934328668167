import axios from "axios"
import {API_HOST} from "@/config"
import router from "@/router/index.js"

export default {
    state: {
        token: {
            access: localStorage.getItem("at") || "",
            refresh: localStorage.getItem("rt") || "",
        },
        isAuthorized: false,
        authLoading: false,
    },
    mutations: {
        saveTokenPair(state, tokenPair) {
            state.token = tokenPair
        },
        setAuthorized(state, flag) {
            state.isAuthorized = flag
        },
        setAuthLoading(state, flag) {
            state.authLoading = flag
        },
    },
    actions: {
        clearAuth({commit}) {
            commit("setAuthorized", false)
            commit("saveTokenPair", {access: "", refresh: ""})

            localStorage.setItem("at", "")
            localStorage.setItem("rt", "")
        },
        login(context, authData) {
            axios
                .post(
                    API_HOST + "/v1/client/auth",
                    {
                        login: authData.username,
                        password: authData.password,
                    },
                    {
                        headers: {"Content-Type": "application/json"}
                    }
                )
                .then((response) => {
                    const token = {
                        access: response.data.access_token,
                        refresh: response.data.refresh_token,
                    }
                    context.commit("saveTokenPair", token)

                    localStorage.setItem("at", token.access)
                    localStorage.setItem("rt", token.refresh)
                    return new Promise((resolve) => setTimeout(resolve, 500))
                })
                .then(() => {
                    context.dispatch("loadUser", {root: true}).then(() => {
                        context.commit("setAuthorized", true)
                        context.commit("setAuthLoading", false)
                        router.push(authData.redirect || "/")
                    })
                })
                .catch((error) => {
                    context.commit("setAuthLoading", false)

                    if (error.response) {
                        context.commit(
                            "setErrorMessage",
                            "Неверный логин или пароль"
                        )
                    }
                })
        },
        logout(context) {
            context.dispatch("clearAuth")
            context.dispatch("clearUser", {root: true})

            router.push("/login")
        },
        async refresh(context) {
            return await axios
                .post(
                    API_HOST + "/v1/client/auth/refresh",
                    {
                        refresh_token: context.getters.getTokenPair.refresh,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + context.getters.getTokenPair.access,
                        },
                    }
                )
                .then((response) => {
                    console.log("refresh response", response)
                    const token = {
                        access: response.data.access_token,
                        refresh: context.getters.getTokenPair.refresh,
                    }
                    context.commit("saveTokenPair", token)

                    localStorage.setItem("at", token.access)
                })
        },
    },
    getters: {
        getTokenPair(state) {
            return state.token
        },
        isAuthLoading(state) {
            return state.authLoading
        },
    },
}
