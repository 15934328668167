<template>
    <div
        class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
        id="sidenav-collapse-main"
    >
        <ul class="navbar-nav">
            <li class="nav-item">
                <sidenav-collapse
                    url="#"
                    :aria-controls="''"
                    v-bind:collapse="false"
                    collapseRef="consumers"
                    navText="Клиенты"
                    :components-for-active="['Consumers', 'Consumer', 'ConsumerCreate']"
                >
                    <template v-slot:icon>
                        <account-group-icon />
                    </template>
                </sidenav-collapse>
            </li>
          <li class="nav-item">
            <sidenav-collapse
                url="#"
                :aria-controls="''"
                v-bind:collapse="false"
                collapseRef="consumer/join"
                navText="Подключить клиента"
                :components-for-active="['ConsumerJoin']"
            >
              <template v-slot:icon>
                <account-plus-icon />
              </template>
            </sidenav-collapse>
          </li>
        </ul>
    </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";

import AccountGroupIcon from "vue-material-design-icons/AccountGroup.vue";
import AccountPlusIcon from "vue-material-design-icons/AccountPlus.vue";

export default {
    name: "SidenavList",
    props: {
        cardBg: String,
    },
    components: {
        SidenavCollapse,
        AccountGroupIcon,
        AccountPlusIcon,
    },
};
</script>
