import store from "@/store"

export default function (to, from, next) {
    const tokenPair = store.getters.getTokenPair

    function toLogin() {
        next({
            name: "Login",
            query: {
                r: to.fullPath,
            }
        })
    }

    const tokenExist =
        tokenPair != null &&
        tokenPair.access != null &&
        tokenPair.access !== ""

    if (!tokenExist) {
        toLogin()
        return
    }

    const decodedAccessToken = store.getters.decodeToken(tokenPair.access)
    const decodedRefreshToken = store.getters.decodeToken(tokenPair.refresh)

    const isAccessTokenExpired = store.getters.isExpireToken(decodedAccessToken.exp)
    const isRefreshTokenExpired = store.getters.isExpireToken(decodedRefreshToken.expiration)

    if (isRefreshTokenExpired) {
        console.warn("Refresh token expired")
        toLogin()
        return
    }

    if (isAccessTokenExpired) {
        console.warn("Access token expired")
        store.dispatch("refresh").then(() => {
            next()
        }).catch((error) => {
            console.warn(error)
            toLogin()
        })
        return
    }

    next()

}