<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-1">
            <div class="bg-gradient-info shadow-success border-radius-lg py-3 d-flex justify-content-between">
              <h6 class="text-white ps-3 pt-1">
                Список устройств
              </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2 overflow-y-scroll" style="max-height: 400px">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Uuid
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Устройство
                  </th>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Статус
                  </th>

                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Дата создания
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="device in devicesList"
                    :key="device.key"
                    :class="device.status === 'ACTIVE' ? `text-success` : ``"
                >
                  <td>
                    <p class="text-xs font-weight-bold mb-0 px-2">
                      {{ device.uuid || "-" }}
                    </p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0 px-2">
                      {{ device.device || "-" }}
                    </p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0 px-2">
                      {{ status[device.status] || "-" }}
                    </p>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">
                      {{ formatDate(device.created) || "-" }}
                    </p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import {API_HOST} from "@/config"

export default {
  name: "ConsumerDevicesList",
  data() {
    return {
      status: {
        INACTIVE: "Неактивный",
        ACTIVE: "Активный",
      },
      devicesList: [],
    }
  },
  methods: {
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      }
      return new Date(dateString).toLocaleDateString("ru-RU", options)
    },
    getDevicesList() {
      axios
          .get(API_HOST + "/v1/client/consumers/" + this.$route.params.id + "/devices", {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + this.$store.getters.getTokenPair.access,
            },
          })
          .then((response) => {
            this.devicesList = response.data.devices
          })
          .catch((error) => {
            console.warn(error)
          })
    },
  },

  mounted() {
    this.getDevicesList()
  },
}
</script>