import {createStore} from "vuex"

import auth from "./modules/auth"
import user from "./modules/user"
import token from "./modules/token"

export default createStore({
    state: {
        hideConfigButton: false,
        isPinned: true,
        showConfig: false,
        sidebarType: "bg-gradient-info",
        isRTL: false,
        color: "dark",
        isNavFixed: false,
        isAbsolute: false,
        showNavs: true,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        showMain: true,
        isDarkMode: true,
        navbarFixed:
            "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
        absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
        errorMessage: "",
        breadcrumbs: [],
    },
    mutations: {
        navbarMinimize(state) {
            const sidenav_show = document.querySelector(".g-sidenav-show")

            if (sidenav_show.classList.contains("g-sidenav-pinned")) {
                sidenav_show.classList.remove("g-sidenav-pinned")
                state.isPinned = true
            } else {
                sidenav_show.classList.add("g-sidenav-pinned")
                state.isPinned = false
            }
        },
        navbarFixed(state) {
            if (state.isNavFixed === false) {
                state.isNavFixed = true
            } else {
                state.isNavFixed = false
            }
        },
        toggleEveryDisplay(state) {
            state.showNavbar = !state.showNavbar
            state.showSidenav = !state.showSidenav
            state.showFooter = !state.showFooter
        },
        toggleHideConfig(state) {
            state.hideConfigButton = !state.hideConfigButton
        },
        color(state, payload) {
            state.color = payload
        },
        setErrorMessage(state, message) {
            state.errorMessage = message
        },
        setBreadcrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs
        },
    },
    actions: {
        setColor({commit}, payload) {
            commit("color", payload)
        },
    },
    modules: {
        auth,
        user,
        token,
    },
    getters: {
        getErrorMessage: state => state.errorMessage,
    },
})
