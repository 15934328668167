import {createRouter, createWebHistory} from "vue-router"

import Auth from "@/views/Auth.vue"
import Consumers from "@/views/Consumers.vue"
import Consumer from "@/views/Consumer.vue"
import ConsumerCreate from "@/views/ConsumerCreate.vue"
import ConsumerJoin from "@/views/ConsumerJoin.vue"
import NotFound from "@/views/NotFound.vue"

import checkToken from "@/middleware/checkToken.js"
import checkLogin from "@/middleware/checkLogin.js"

const routes = [
    {
        path: "/",
        name: "Main",
        redirect: "/consumers",
        component: Consumer,
        beforeEnter: checkToken,
    },
    {
        path: "/login",
        name: "Login",
        component: Auth,
        beforeEnter: checkLogin,
    },
    {
        path: "/logout",
        name: "Logout",
    },
    {
        path: "/consumers",
        name: "Клиенты",
        component: Consumers,
        beforeEnter: checkToken,
    },
    {
        path: "/consumer/join",
        name: "Подключение клиента",
        component: ConsumerJoin,
        beforeEnter: checkToken,
    },
    {
        path: "/consumers/consumer/:id",
        name: "Клиент",
        component: Consumer,
        beforeEnter: checkToken,
    },
    {
        path: "/consumers/consumer/new",
        name: "Новый клиент",
        component: ConsumerCreate,
        beforeEnter: checkToken,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
})

export default router