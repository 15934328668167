import axios from "axios"
import { API_HOST } from "@/config"

export default {
    state: {
        user: {
            id: localStorage.getItem("user-id") || "",
            name: localStorage.getItem("user-name") || "",
        },
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
    },
    actions: {
        clearUser(context) {
            context.commit("setAuthorized", false)
            context.commit("setUser", { id: "", name: "" })

            localStorage.setItem("user-id", "")
            localStorage.setItem("user-name", "")
        },
        loadUser(context) {
            return axios
                .get(API_HOST + "/v1/client", {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + context.getters.getTokenPair.access,
                    },
                })
                .then((response) => {
                    const user = {
                        id: response.data.id,
                        name: response.data.name,
                    }
                    context.commit("setUser", user)

                    localStorage.setItem("user-id", user.id)
                    localStorage.setItem("user-name", user.name)
                })
                .catch((error) => {
                    console.warn(error)
                    if (error.response) {
                        context.commit(
                            "setErrorMessage",
                            "Пользователь не найден",
                            { root: true }
                        )
                    }
                })
        },
    },
    getters: {
        getUser(state) {
            return state.user
        },
    },
}
