import store from "@/store"

export default function (to, from, next) {
    const tokenPair = store.getters.getTokenPair

    const tokenExist =
        tokenPair != null &&
        tokenPair.access != null &&
        tokenPair.access !== ""

    console.log("tokenExist", tokenExist)
    if (tokenExist) {
        const decodedAccessToken = store.getters.decodeToken(tokenPair.access)
        const isAccessTokenExpired = store.getters.isExpireToken(decodedAccessToken.exp)

        console.log("isAccessTokenExpired", isAccessTokenExpired)
        if (isAccessTokenExpired) {
            next()
        } else {
            next({name: "Main"})
        }

    } else {
        next()
    }
}
