export default {
    getters: {
        decodeToken: () => (token) => {
            try {
                const tokenPayload = token.split(".")[1]
                return JSON.parse(atob(tokenPayload.replace("-", "+").replace("_", "/")))
            } catch {
                return null
            }
        },
        isExpireToken: () => (expireDate) => {
            return expireDate < (Date.now() / 1000)
        },
    }
}
