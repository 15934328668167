<template>
  <div class="container-fluid">
    <div
        class="page-header min-height-300 border-radius-xl mt-4"
        style="background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="card card-body mx-3 mx-md-4 mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <account-plus-outline-icon
              :size=70
          />
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">Создание клиента</h5>
            <p class="mb-0 font-weight-normal text-sm"></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mt-3 row">
          <div class="col-12 col-md-6 col-xl-6 mt-md-0 mt-4 position-relative">
            <div class="h-100">
              <div class="p-3 pb-0 card-header"></div>
              <div class="p-3 card-body">
                <form role="form" class="text-start mt-3" @submit.prevent="createConsumer">
                  <fieldset :disabled="formDisable">
                    <div class="mb-3">
                      <material-input
                          id="consumer_uid"
                          type="text"
                          label="UID"
                          name="consumer_uid"
                          :isRequired=true
                          v-model="consumer.uid"
                          value="{{ consumer.uid }}"
                      />
                    </div>
                    <div class="mb-3">
                      <material-input
                          id="consumer_name"
                          type="text"
                          label="Имя"
                          name="consumer_name"
                          :isRequired=true
                          v-model="consumer.name"
                          value="{{ consumer.name }}"
                      />
                    </div>
                    <div class="mb-3">
                      <material-input
                          id="consumer_title"
                          type="text"
                          label="Заголовок"
                          name="consumer_title"
                          :isRequired=true
                          v-model="consumer.title"
                          value="{{ consumer.title }}"
                      />
                    </div>
                    <div class="mb-3">
                      <material-input
                          id="consumer_phone"
                          type="text"
                          label="Телефон"
                          name="consumer_phone"
                          v-model="consumer.phone"
                          value="{{ consumer.phone }}"
                      />
                    </div>
                    <div class="mb-3">
                      <material-input
                          id="consumer_email"
                          type="email"
                          label="Email"
                          name="consumer_email"
                          v-model="consumer.email"
                          value="{{ consumer.email }}"
                      />
                    </div>
                    <div class="mb-3">
                      <MaterialDatePicker
                          id="consumer_birthday"
                          name="consumer_birthday"
                          label="День рождения"
                          v-model="consumer.birthday"
                          value="{{ consumer.birthday }}"
                      />
                    </div>
                    <div class="my-4">
                      <div class="d-flex flex-row align-items-center">
                        <material-button variant="gradient" color="info" type="submit">Сохранить</material-button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
            <hr class="vertical dark"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

import MaterialInput from "@/components/MaterialInput.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import MaterialDatePicker from "@/components/MaterialDatePicker.vue"

import AccountPlusOutlineIcon from "vue-material-design-icons/AccountPlusOutline.vue"

import setTooltip from "@/assets/js/tooltip.js"
import {API_HOST} from "@/config"

export default {
  name: "ConsumerCreate",
  inject: ["notification"],
  data() {
    return {
      breadcrumbs: ["Клиенты", "Создание клиента"],
      formDisable: false,
      consumer: {},
    }
  },
  components: {
    MaterialDatePicker,
    MaterialInput,
    MaterialButton,

    AccountPlusOutlineIcon,
  },
  methods: {
    createConsumer() {
      this.formDisable = true

      axios
          .put(API_HOST + "/v1/client/consumers", this.consumer, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + this.$store.getters.getTokenPair.access,
            },
          })
          .then((response) => {
            console.info(response)
            this.$router.push({
              path: `${"/consumers/consumer/" + response.data.id}`,
              query: {
                name: response.data.name,
              },
            })
            this.formDisable = false
          })
          .catch((error) => {
            this.formDisable = false
            console.warn(error)
            if (error.response) {
              let errorMessage
              if (error.response.data.message.includes("Key (uid)=")) {
                errorMessage = "Пользователь с таким UID уже существует"
              } else if (error.response.data.message.includes("Key (title)=")) {
                errorMessage = "Пользователь с таким заголовком уже существует"
              } else if (error.response.data.message.includes("Key (name)=")) {
                errorMessage = "Пользователь с таким именем уже существует"
              } else if (error.response.data.message.includes("Key (phone)=")) {
                errorMessage = "Пользователь с таким телефоном уже существует"
              } else {
                errorMessage = error.response.data.message
              }

              this.notification.error(errorMessage)
            }
          })
    },
  },
  mounted() {
    setTooltip()
    this.$store.commit("setBreadcrumbs", this.breadcrumbs)
  },
  beforeUnmount() {
    this.consumer = {}
    this.breadcrumbs = []
    this.$store.commit("setBreadcrumbs", this.breadcrumbs)
  },
}
</script>